<template>
 <div>
  <b-card>
      <h6 class="mb-4 font-weight-bold">Filter</h6>
      <b-row class="p-3">
      <b-col cols="3">
          <b-form-group
            label="Client Name"
          >
            <custom-select
              v-model="clientName"
              label="code"
              multiple
              placeholder="Select client name"
              :options="clientList"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Project name"
          >
            <custom-select
              v-model="projectName"
              label="name"
              placeholder="Select project name"
              multiple
              :options="projectOption"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Type of coorporation"
          >
            <custom-select
              v-model="typeOfCoorporation"
              multiple
              placeholder="Select type of coorporation"
              :options="typeOption"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Status"
          >
            <custom-select
              v-model="status"
              label="name"
              placeholder="Select status"
              multiple
              :options="statusOption"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="p-3">
      <b-col cols="3">
            <b-form-group
            label="Periode Project"
          >
        <date-picker placeholder="Select Periode Project" v-model="periodSprint" range></date-picker>
        </b-form-group>
      </b-col>
      <b-col cols="3">
            <b-form-group
            label="Periode Maintenance"
          >
        <date-picker placeholder="Select periode maintenance" v-model="periodMaintenance" range></date-picker>
        </b-form-group>
        </b-col>
      <b-col cols="6">
        <b-button
          class="merge reset mr-2 mt-4"
          variant="outline-warning"
          @click="resetFilter"
        >
          Reset Filter
        </b-button>
        <b-button
          class="next merge mr-3 mt-4"
          variant="warning"
          @click="changeFilter"
        >
          Apply Filter
        </b-button>
      </b-col>
    </b-row>
    </b-card>
    <b-card class="mt-3" body-class="py-4 px-3">
      <div class="mt-3 d-flex justify-content-between">
      <b-button
        class="next"
        variant="warning"
        @click="goToNewProject"
      >
        Add New Project
      </b-button>
      </div>
      <b-row>
        <b-col cols="12">
          <div
            v-if="!projects.length"
            class="text-center"
          >
            <b-spinner
              v-if="busy"
              variant="primary"
            />
            <span v-else>No Data</span>
          </div>
          <ul
            v-else
            :style="gridStyle"
            class="card-list mt-3 p-0"
          >
            <b-card
              v-for="(item, p) in projects"
              :key="p"
              class="shadow-sm"
            >
              <div class="pointer" @click="onRowClicked(item)">
                <div class="mb-6">
                  <div class="font-weight-bold mb-1">{{ item.name }}
                    <b-badge class="px-3 py-2 mr-2">{{ item.code }}</b-badge>
                    <b-badge class="status px-3 py-2">
                  {{ item.status }}
                </b-badge>
                  </div>
                   <div class="text-muted">{{ item.clientName }}</div>
                  <div class="text-muted">Periode Sprint :
                  <label class="font-weight-bold mt-2" :style="{ color : '#FFA500' }">{{ item.developmentPeriod }}</label></div>
                </div>
              </div>
              <div align="right">
                <b-link v-show="item.status !== 'Closed'"
                  class="text-secondary mx-2"
                  @click="goToEditProject(item)"
                >
                  <fa-icon icon="pen"/>
                </b-link>
                <b-link
                  class="text-secondary mx-2"
                  @click="goToViewProject(item)"
                >
                  <fa-icon icon="eye"/>
                </b-link>
              </div>
            </b-card>
          </ul>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="fetchProjects"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="fetchProjects"
          />
          <span class="show-entries mr-auto">
            {{`Show ${totalRows === 0 ? 0 : ((currentPage-1) * perPage) + 1} to ${totalRows > ((currentPage) * perPage) ? ((currentPage) * perPage) : totalRows} of ${totalRows} entries`}}
          </span >
        </div>
      </div>
    </b-card>
    <modal-short :shortInfo="shortInfo" />
    </div>
</template>

<script>
import api from '@/api'
import ModalShort from './ModalShort'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

export default {
  components: {
    ModalShort,
    DatePicker
  },

  props: {
  },

  created() {
    this.fetchClientList()
    this.fetchProjects()
    this.fetchProjectOption()
    this.fetchStatus()
  },

  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`
      }
    }
  },

  data: () => ({
    cardIndex: [],
    shortInfo: {},
    search: '',
    valueOptions: [],
    filters: '',
    busy: false,
    collapseVisible: true,
    numberOfColumns: 3,
    periodMaintenance: [],
    periodSprint: [],
    clientList: [],
    statusOption: [],
    projectOption: [],
    typeOption: [
      'Mandays', 'Software'
    ],
    clientName: [],
    filter: {
      clientName: null,
      status: null,
      projectName: null,
      level: null
    },
    projectName: [],
    typeOfCoorporation: [],
    status: [],
    projects: [],
    currentPage: 1,
    perPage: 12,
    totalRows: 0,
    totalPage: 0,
    pageOptions: [12, 24]
  }),

  methods: {
    async searchProject() {
      const filter = '(name|code|status|clientName)@=*' + this.search
      this.filters = filter + ',' + this.filters
      this.fetchProjects()
    },
    async changeFilter() {
      if (this.projectName.length > 0) {
        var projectName = ''
        this.projectName.map(v => {
          projectName += v + '|'
        })
        projectName = projectName.substring(0, projectName.length - 1)
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters = this.projectName ? 'name@=' + projectName : ''
      }
      if (this.clientName.length > 0) {
        var clientName = ''
        this.clientName.map(v => {
          clientName += v + '|'
        })
        clientName = clientName.substring(0, clientName.length - 1)
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters += 'clientName@=' + clientName
      }
      if (this.status.length > 0) {
        var status = ''
        this.status.map(v => {
          status += v + '|'
        })
        status = status.substring(0, status.length - 1)
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters += 'status@=' + status
      }
      if (this.typeOfCoorporation.length > 0) {
        var typeOfCoorporation = ''
        this.typeOfCoorporation.map(v => {
          typeOfCoorporation += v + '|'
        })
        typeOfCoorporation = typeOfCoorporation.substring(0, typeOfCoorporation.length - 1)
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters += 'typeOfCoorporation@=' + typeOfCoorporation
      }
      if (this.periodSprint.length > 0) {
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters += 'developmentPeriod==' + moment(this.periodSprint[0]).format('YYYY-MM-DD') + ';' + moment(this.periodSprint[1]).format('YYYY-MM-DD')
      }
      if (this.periodMaintenance.length > 0) {
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters += 'maintenancePeriod==' + moment(this.periodMaintenance[0]).format('YYYY-MM-DD') + ';' + moment(this.periodMaintenance[1]).format('YYYY-MM-DD')
      }
      this.fetchProjects()
      this.filters = ''
    },
    resetFilter() {
      this.clientName = []
      this.projectName = []
      this.typeOfCoorporation = []
      this.status = []
      this.periodSprint = []
      this.periodMaintenance = []
      this.filters = ''
      this.currentPage = 1
      this.fetchProjects()
    },
    onRowClicked(data) {
      this.fetchShortInfo(data.id)
      this.$bvModal.show('modal-short')
    },
    async fetchClientList() {
      const { data } = await api.client.list()
      this.clientList = data.data
    },
    async fetchStatus() {
      const { data } = await api.project.status()
      this.statusOption = data.data
    },
    async fetchProjects() {
      const data = await api.project.list({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: this.filters,
        sorts: '-createdDate'
      })
      this.projects = data.data
      this.totalRows = data.totalData
      this.totalPage = data.totalPage
    },
    async fetchProjectOption() {
      const { data } = await api.project.listProject()
      this.projectOption = data.data
    },
    async fetchShortInfo(id) {
      const data = await api.project.get(id)
      this.shortInfo = data.data
    },
    goToViewProject(item) {
      this.$router.push({
        path: 'view-project',
        query: {
          id: item.id,
          code: item.code
        }
      })
    },
    goToNewProject() {
      this.$router.push('/new-project')
      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      localStorage.setItem('step', 1)
    },
    goToEditProject(item) {
      this.$router.push({
        path: 'edit-project',
        query: {
          id: item.id,
          code: item.code
        }
      })

      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      localStorage.setItem('projectName', item.name)
      localStorage.setItem('projectStatus', item.status)
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}

.card-list {
  display: grid;
  grid-gap: 1em;
}

.badge {
  background: #F6F4F4;
  color: #000;
}

.status {
  background: rgba(214, 35, 174, 0.2);;
  color: #D623AE;
}

.merge button {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 9px 14px;
 gap: 10px;

 position: absolute;
 width: 144px;
 height: 48px;
 left: 160px;
 top: 27px;
}
</style>
