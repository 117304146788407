<template>
  <b-modal
    ref="modal-short"
    id="modal-short"
    title=""
    centered
    hide-footer
    hide-header
  >
    <div class="p-3">
      <div class="mb-3">
          <div class="font-weight-bold mb-1">{{ shortInfo.name }}
              <b-badge class="px-3 py-2 mr-2">{{ shortInfo.code }}</b-badge>
           </div>
          <b-row>
            <b-col>
                <b-form-group label="Client Name" >
                  <label class="font-weight-bold"> {{ shortInfo.clientName }} </label>
                </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Technology" >
                <label class="font-weight-bold">{{ shortInfo.technology }}</label>
              </b-form-group>
              </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Required Resource" >
              <div>
              {{ shortInfo.requiredResources }}
              </div>
                </b-form-group>
                </b-col>
                      <b-col>
                        <div class="text-muted">Status</div>
                        <div>
                        <b-badge class="status px-3 py-2">
                        {{ shortInfo.status }}
                </b-badge>
              </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group label="Initial State Project" >
                        <label class="font-weight-bold">{{ shortInfo.initState }}</label>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="Sprint Quantity" >
                        <label class="font-weight-bold">{{ shortInfo.sprintQuantity }}</label>
                      </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group label="Period Sprint" >
                        <label class="font-weight-bold">{{ shortInfo.developmentPeriod }}</label>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="Maintenance" >
                        <label class="font-weight-bold">{{ shortInfo.maintenancePeriod }}</label>
                      </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <div class="text-muted">Team Leader</div>
                        <div v-for="(item, p) in shortInfo.developmentTeams" :key="p"
                        class="shadow-sm" body-class="p-3">
                        <div v-if="item.isLeader" class="font-weight-bold mb-1">{{ item.fullName }}</div>
                        </div>
                </b-col>
                <b-col>
                      <div class="text-muted">Development Team</div>
                        <div v-for="(item, p) in shortInfo.developmentTeams" :key="p"
                        class="shadow-sm" body-class="p-3">
                        <div class="font-weight-bold mb-1">{{ item.fullName }}</div>
                        </div>
                </b-col>
            </b-row>

            <div align="right" >
              <b-link v-show="shortInfo.status !== 'Closed'" class="text-secondary mx-2" @click="goToEditProject()">
                <fa-icon icon="pen"/>
              </b-link>
              <b-link class="text-secondary mx-2" @click="goToViewProject()">
                <fa-icon icon="eye"/>
              </b-link>
            </div>
          </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
    shortInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    clientName: null,
    technology: null,
    code: null,
    status: null
  }),
  methods: {
    goToViewProject() {
      this.$router.push({
        path: 'view-project',
        query: {
          id: this.shortInfo.id,
          code: this.shortInfo.code
        }
      })
    },
    goToEditProject() {
      this.$router.push({
        path: 'edit-project',
        query: {
          id: this.shortInfo.id,
          code: this.shortInfo.code
        }
      })

      localStorage.setItem('projectName', this.shortInfo.name)
      localStorage.setItem('projectStatus', this.shortInfo.status)
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  background: #F6F4F4;
  color: #000;
}

.status {
  background: rgba(214, 35, 174, 0.2);;
  color: #D623AE;
}
</style>
